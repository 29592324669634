import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Barcode from "react-barcode"
import { useQueryParam, StringParam } from "use-query-params"
import { onValue, ref, set, remove, push } from "firebase/database"
import { db } from "../firebase"
import { navigate } from "gatsby"

const BarCode = () => {
  const [num, setNum] = useQueryParam("id", StringParam)
  const [names, setNames] = useQueryParam("names", StringParam)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (num) {
      addToQueue(num)
    }
  }, [])

  const addToQueue = async braceletID => {
    if (braceletID != "") {
      try {
        const res = await fetch(
          `/.netlify/functions/putDancersOnQueue?id=${braceletID}`
        )

        const data = await res.json()

        if (data.onQueue.length) {
          data.onQueue.forEach(dancer => {
            set(ref(db, dancer.name), {
              name: dancer.name,
              url: dancer.picture,
              time: Date.now(),
              id: dancer.id,
              ageGroup: dancer.ageGroup,
            })
          })
          navigate("/")
        } else {
          setError(true)
          setErrorMessage(
            "Something didnt work, please see attendant at front desk"
          )
        }
      } catch (e) {
        setError(true)
        setErrorMessage(
          "Something didnt work, please see attendant at front desk"
        )
      }
    }
  }

  return (
    <Layout>
      <div className="w-full flex justify-center items-center flex-col pt-10">
        <h2 className="text-xl text-ascendance font-semibold">
          Show Pick Up Barcode
        </h2>
         {errorMessage === "" && (
          <h2 className="text-xl text-ascendance font-semibold">
            Adding to Queue
          </h2>
        )} 
        {/* <Barcode value={num} />
        <div className="mt-2">
          {names &&
            names.split(",").map(name => (
              <p className="text-center" key={name}>
                {name}
              </p>
            ))}
        </div> */}
      </div>
    </Layout>
  )
}

export default BarCode
